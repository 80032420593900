import { useState, useEffect } from 'react';
import { Header } from './components/Header';
import { HeroSection } from './components/HeroSection';
import { ContactFormSection } from './components/ContactFormSection';
import { AboutSection } from './components/AboutSection';
import { ServicesSection } from './components/ServicesSection';
import { HowItWorksSection } from './components/HowItWorksSection';
import { ThankYouSection } from './components/ThankYouSection';
import { TestimonialsSection } from './components/TestimonialsSection';
import { BlogSection } from './components/BlogSection';
import { BlogPostDetail } from './components/BlogPostDetail';
import { Footer } from './components/Footer';
import { ScrollToTop } from './components/ScrollToTop';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import client from './contentfulClient';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showContact, setShowContact] = useState(false);
  const [overlayStatus, setOverlayStatus] = useState('none'); // possible values: 'none', 'entering', 'entered', 'exiting'
  const [currentService, setCurrentService] = useState(null);
  const [showThankyou, setShowThankyou] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all sections in parallel
        const [heroResponse, servicesResponse, aboutResponse, howItWorksResponse, testimonialsResponse, blogPostsResponse, thankYouResponse, blogSectionResponse] = await Promise.all([
          client.getEntries({ content_type: 'heroSection' }),
          client.getEntries({ content_type: 'servicesSection', include: 2 }),
          client.getEntries({ content_type: 'aboutSection', include: 2 }),
          client.getEntries({ content_type: 'howItWorksSection', include: 2 }),
          client.getEntries({ content_type: 'testimonialsSection', include: 2 }),
          client.getEntries({ content_type: 'blogPost', order: '-fields.date' }),
          client.getEntries({ content_type: 'thankYouSection', include: 2 }),  // Added Thank You Section
          client.getEntries({ content_type: 'blogSection' })
        ]);

        const allData = {};

        // Process HeroSection data
        if (heroResponse.items.length > 0) {
          const heroData = heroResponse.items[0].fields;
          if (heroData.backgroundImage) {
            const bgImageId = heroData.backgroundImage.sys.id;
            const bgImageAsset = await client.getAsset(bgImageId);
            heroData.backgroundImageUrl = 'https:' + bgImageAsset.fields.file.url;
          }
          allData.HeroSection = heroData;
        }

        // Process ServicesSection data
        if (servicesResponse.items.length > 0) {
          const servicesSectionEntry = servicesResponse.items[0].fields;
          const services = servicesSectionEntry.services.map((service) => ({
            icon: service.fields.icon,
            name: service.fields.name,
            text: service.fields.text,
            buttonText: service.fields.buttonText,
            order: service.fields.order || 0,
          }));

          services.sort((a, b) => a.order - b.order);
          allData.ServicesSection = {
            title: servicesSectionEntry.title,
            paragraph: servicesSectionEntry.paragraph,
            services: services,
          };
        }

        // Process AboutSection data
        if (aboutResponse.items.length > 0) {
          const aboutData = aboutResponse.items[0].fields;
          allData.AboutSection = {
            title: aboutData.title || 'Our Focus', // Add title
            paragraph: aboutData.paragraph,
            items: aboutData.items.map((item) => ({
              title: item.fields.itemHeader,
              text: item.fields.itemParagraph,
            })),
          };
        }

        // Process HowItWorksSection data
        if (howItWorksResponse.items.length > 0) {
          const howItWorksData = howItWorksResponse.items[0].fields;
          allData.HowItWorksSection = {
            title: howItWorksData.title,
            steps: howItWorksData.steps.map((step) => ({
              icon: step.fields.icon,
              title: step.fields.title,
              text: step.fields.text,
            })),
          };
        }

        // Process TestimonialsSection data
        if (testimonialsResponse.items.length > 0) {
          const testimonialsData = testimonialsResponse.items[0].fields;
          allData.TestimonialsSection = {
            title: testimonialsData.title,
            testimonials: testimonialsData.testimonials.map((testimonial) => ({
              name: testimonial.fields.name,
              text: testimonial.fields.text,
              img: 'https:' + testimonial.fields.image.fields.file.url,
            })),
          };
        }

        // Process Blog Posts data
        if (blogPostsResponse.items.length > 0) {
          allData.BlogPosts = blogPostsResponse.items.map((post) => ({
            title: post.fields.title,
            slug: post.fields.slug,
            description: post.fields.description,
            imgSrc: post.fields.image ? 'https:' + post.fields.image.fields.file.url : '',
            author: post.fields.author,
            date: post.fields.date,
            content: post.fields.content,
            shareOptions: post.fields.shareOptions ? post.fields.shareOptions.map((option) => option.fields) : [],
          }));
        }

        // Process ThankYouSection data
        if (thankYouResponse.items.length > 0) {
          const thankYouData = thankYouResponse.items.map((item) => ({
            icon: item.fields.icon,
            name: item.fields.name,
            text: item.fields.text,
            buttonText: item.fields.buttonText,
            link: item.fields.url,  // Fetch the link field from Contentful
          }));
          allData.ThankYouSection = thankYouData;
        }

        // Process BlogSection data (Optional)
        if (blogSectionResponse.items.length > 0) {
          const blogSectionData = blogSectionResponse.items[0].fields;
          allData.BlogSection = {
            headerImageUrl: blogSectionData.headerImage ? 'https:' + blogSectionData.headerImage.fields.file.url : null,
            title: blogSectionData.title || 'Our Blog',
            subtitle: blogSectionData.subtitle || 'Stay updated with our expert insights and latest trends.'
          };
        }

        // Update state with fetched data
        setLandingPageData(allData);
      } catch (error) {
        console.error('Error fetching data from Contentful:', error);
        // Fallback to local JSON data
        setLandingPageData(JsonData);
      }
    };

    fetchData();
  }, []);

  const handleGetStartedClick = () => {
    setCurrentService('Get Started');
    setOverlayStatus('entering');
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowContact(true);
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 500);
      }, 500);
    }, 500);
  };

  const handleServicesClick = (serviceIdentifier) => {
    setOverlayStatus('entering');
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowContact(true);
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 100);
      }, 100);
    }, 100);
    setCurrentService(serviceIdentifier);
    scroll.animateScroll(0);
  };

  const handleFormSubmit = () => {
    setOverlayStatus('entering');
    setShowHeader(false); // Hide header immediately
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        setShowThankyou(true);
        setShowContact(false); // Hide the Contact component
        setOverlayStatus('exiting');
        setTimeout(() => {
          setOverlayStatus('none');
        }, 500);
      }, 500);
    }, 500);
  };

  return (
    <Router>
      <ScrollToTop />
      <Header
        onBuyClick={handleServicesClick}
        onSellClick={() => handleServicesClick('Sell Your Home Immediately')}
      />
      <Routes>
        <Route path="/blog" element={<BlogSection data={landingPageData} />} />
        <Route path="/blog/:postName" element={<BlogPostDetail data={landingPageData} />} />
        <Route
          path="/"
          element={
            <>
              {showThankyou ? (
                <ThankYouSection data={landingPageData.ThankYouSection} />
              ) : showContact ? (
                <ContactFormSection
                  data={landingPageData.ContactFormSection}
                  currentService={currentService}
                  onFormSubmit={handleFormSubmit}
                />
              ) : (
                showHeader && (
                  <HeroSection
                    data={landingPageData.HeroSection}
                    onGetStartedClick={handleGetStartedClick}
                    currentService={currentService}
                  />
                )
              )}
              {overlayStatus !== 'none' && (
                <div className={`black-overlay ${'black-overlay-' + overlayStatus}`}></div>
              )}
              {!showThankyou && (
                <ServicesSection
                  data={landingPageData.ServicesSection}
                  onServiceButtonClick={handleServicesClick}
                />
              )}
              <AboutSection data={landingPageData.AboutSection} />
              <HowItWorksSection data={landingPageData.HowItWorksSection} />
              <TestimonialsSection data={landingPageData.TestimonialsSection} />
            </>
          }
        />
      </Routes>
      <Footer
        onBuyClick={handleServicesClick}
        onSellClick={() => handleServicesClick('Sell Your Home Immediately')}
      />
    </Router>
  );
};

export default App;
