export const ThankYouSection = (props) => {
  return (
    <div id="services" className="text-center thankyou-section">
      <div className="container">
        <div className="section-title">
          <h2>Thank You!</h2>
          <p>
            A Vidcor Specialist will contact you shortly to set up an onboarding call and get you access to our exclusive marketplace.
          </p>
        </div>
        <div className="row">
          {props.data && props.data.length > 0 ? (
            props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-3">
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                  <a
                    href={d.link ? d.link : '#'}
                    className="btn btn-custom"
                    target="_blank" // Open the link in a new tab
                    rel="noopener noreferrer" // Security measure for external links
                  >
                    {d.buttonText}
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p> // Fallback while data is loading
          )}
        </div>
      </div>
    </div>
  );
};
