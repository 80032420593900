import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const Header = ({ onBuyClick, onSellClick }) => {
  
  const navbarToggle = useRef(null);
  const navigate = useNavigate(); // <-- change made here

  const handleNavigation = (event, callback) => {
    event.preventDefault();
    callback();
    navbarToggle.current.click();
    navigate("/"); // Navigate back to the main page using the new API
    window.scrollTo(0, 0); // Ensure we are at the top of the page
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            ref={navbarToggle}
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a
            className='navbar-brand page-scroll'
            href='#page-top'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/';
              window.scrollTo(0, 0);
            }}
          >
            <img src='/img/logo-modern/vidcor-modern-brand-logo-white-wreath.png' alt='Logo' className='navbar-logo' />
            <span className='brand-text'>VIDCOR</span>
          </a>{' '}
        </div>

        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
              <li>
                  <a href='' className='page-scroll' onClick={(e) => handleNavigation(e, onBuyClick)}>
                      Buy
                  </a>
              </li>
              <li>
                  <a href='#about' className='page-scroll' onClick={(e) => handleNavigation(e, onSellClick)}>
                      Sell
                  </a>
              </li>
              <li>
                  <a href='#services' className='page-scroll' onClick={(e) => { navbarToggle.current.click(); navigate("/"); window.scrollTo(0, 0); }}>
                      Services
                  </a>
              </li>
              <li>
                  <a href='#testimonials' className='page-scroll' onClick={(e) => { navbarToggle.current.click(); navigate("/"); window.scrollTo(0, 0); }}>
                      Reviews
                  </a>
              </li>
              <li>
                  <Link to="/blog" onClick={() => navbarToggle.current.click()}>Blog</Link>
              </li>
          </ul>
       </div>
      </div>
    </nav>
  )
}
