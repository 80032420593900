// ServicesSection.jsx

import React from 'react';

export const ServicesSection = (props) => {
  const { data, onServiceButtonClick } = props;

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{data ? data.title : 'Services'}</h2>
          <p>{data ? data.paragraph : ''}</p>
        </div>
        <div className="row">
          {data && data.services
            ? data.services.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3">
                  {' '}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    {/* Adding the button here */}
                    <a
                      href=""
                      className="btn btn-custom"
                      onClick={(e) => {
                        e.preventDefault();
                        onServiceButtonClick && onServiceButtonClick(d.name);
                      }}
                    >
                      {d.buttonText}
                    </a>{' '}
                  </div>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
