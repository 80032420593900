// BlogSection.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const BlogSection = (props) => {
  const data = props.data;
  const allPostsData = data && data.BlogPosts ? data.BlogPosts : [];

  // Initial state for the number of posts to display
  const [numPostsToShow, setNumPostsToShow] = useState(6);
  const displayedPosts = allPostsData.slice(0, numPostsToShow);

  const handleSeeMore = () => {
    // Show the next 6 posts
    setNumPostsToShow((prev) => prev + 6);
  };

  return (
    <div id="blog">
      <div className="blog-header">
        {data && data.BlogSection && data.BlogSection.headerImageUrl && (
          <img src={data.BlogSection.headerImageUrl} alt="Blog Header" className="kenburns" />
        )}
        <h1>{data && data.BlogSection ? data.BlogSection.title : 'Loading...'}</h1>
        <p>{data && data.BlogSection ? data.BlogSection.subtitle : 'Loading...'}</p>
      </div>
      <div className="container">
        {displayedPosts.map((post, index) => (
          <Link to={`/blog/${post.slug}`} key={index} className="blog-post">
            <img src={post.imgSrc} alt="Blog Thumbnail" />
            <div className="blog-post-content">
              <h2>{post.title}</h2>
              <p>{post.description}</p>
              <span className="continue-reading">Continue reading &rarr;</span>
            </div>
          </Link>
        ))}
        {numPostsToShow < allPostsData.length && (
          <button
            onClick={handleSeeMore}
            className="btn-custom"
            style={{ margin: '20px auto', display: 'block' }}
          >
            See More
          </button>
        )}
      </div>
    </div>
  );
};
