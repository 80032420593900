// HeroSection.jsx
import React from 'react';

export const HeroSection = (props) => {
  const { data, onGetStartedClick } = props;
  
  const handleGetStartedClick = (e) => {
    if (onGetStartedClick) {
      onGetStartedClick(e);
    }
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="intro-background"></div>
        
        <img
          src={
            data && data.backgroundImageUrl
              ? data.backgroundImageUrl
              : '/img/cityscape-night-compressed.webp'
          }
          alt="street-grid"
          className="hero-image"
          loading="eager"
          fetchpriority="high"
          decoding="async"
        />
        
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {data ? data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{data ? data.paragraph : 'Loading'}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                  onClick={handleGetStartedClick}
                >
                  {data ? data.buttonText : 'Get Started'}
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};