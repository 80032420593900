// BlogPostDetail.jsx

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import client from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export const BlogPostDetail = () => {
  const { postName } = useParams();
  const [postDetails, setPostDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the blog post from Contentful using the slug
        const response = await client.getEntries({
          content_type: 'blogPost', // Replace with your content type ID
          'fields.slug': postName,
        });

        if (response.items.length > 0) {
          const post = response.items[0].fields;

          console.log('Fetched post:', post); // Debugging log

          // Extract necessary fields and handle assets
          const postData = {
            title: post.title,
            slug: post.slug,
            date: post.date,
            author: post.author,
            image: post.image?.fields?.file?.url
              ? `https:${post.image.fields.file.url}`
              : 'default-image-url',
            content: post.content,
            shareOptions: (post.shareOptions || []).map((option) => ({
              icon: option.fields.icon,
            })),
          };

          setPostDetails(postData);
        } else {
          setPostDetails(null);
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setPostDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [postName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!postDetails) {
    return <div>Post not found.</div>;
  }

  console.log('Share Options:', postDetails.shareOptions); // Add this for debugging

  // Custom rendering options for embedded assets and hyperlinks
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { url } = node.data.target.fields.file;
        const { description } = node.data.target.fields;
        return (
          <img
            src={url}
            alt={description || 'Blog Post Image'}
            className="blog-content-image"
          />
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  };

  // Base URL of your site - replace with your actual domain
  const baseUrl = 'https://www.yoursite.com';

  // Construct the full URL of the current post
  const currentPostUrl = `${baseUrl}/blog/${postDetails.slug}`;

  // Encode the URL and title for use in sharing links
  const encodedPostUrl = encodeURIComponent(currentPostUrl);
  const postTitle = encodeURIComponent(postDetails.title);

  return (
    <div id="blog-post-detail">
      <div className="blog-post-detail-header">
        <img
          src={postDetails.image || 'default-image-url'}
          alt="Blog"
          className="kenburns"
        />

        {/* Breadcrumb Navigation */}
        <div className="breadcrumb">
          <Link to="/">Home</Link> &rarr; <Link to="/blog">Blog</Link> &rarr;{' '}
          <span>{postDetails.title}</span>
        </div>

        <p>{new Date(postDetails.date).toLocaleDateString()}</p>
        <h1 className="blog-post-title">{postDetails.title}</h1>
        <p>By {postDetails.author}</p>

        {/* Social Media Sharing Icons */}
        <div className="social-icons">
          {postDetails.shareOptions.map((option, i) => {
            const iconClass = option.icon || 'default-icon-class';
            let shareUrl = '';

            // Generate the share URL based on the icon class
            if (iconClass.includes('fa-facebook')) {
              shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedPostUrl}`;
            } else if (iconClass.includes('fa-twitter')) {
              shareUrl = `https://twitter.com/intent/tweet?url=${encodedPostUrl}&text=${postTitle}`;
            } else if (iconClass.includes('fa-linkedin')) {
              shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedPostUrl}&title=${postTitle}`;
            } else if (iconClass.includes('fa-envelope')) {
              shareUrl = `mailto:?subject=${postTitle}&body=Check%20out%20this%20post:%20${encodedPostUrl}`;
            } else {
              // Skip if icon class is unrecognized
              return null;
            }

            return (
              <a
                key={i}
                href={shareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={iconClass}></i>
              </a>
            );
          })}
        </div>
      </div>

      {/* Blog Post Content */}
      <div className="blog-post-container">
        <div className="blog-post-content">
          {documentToReactComponents(postDetails.content, options)}
        </div>
      </div>
    </div>
  );
};
