
export const AboutSection = (props) => {
  const data = props.data;
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='about-header'>
              <h2>{data ? data.title : 'Loading...'}</h2>
              <p>{data ? data.paragraph : 'Loading...'}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>

            {/* Here is the 3D effect replacing your image */}
            <div className="example">
              <div className="block">
                  <div className="side -main" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/street-grid-gold.png)` }}>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                  <div className="side -left" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/street-grid-gold.png)` }}></div>
              </div>
           </div>

          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <div className='list-style'>
                <div className='col-lg-12 col-sm-12 col-xs-12'>
                  <ul>
                      {data && data.items
                        ? data.items.map((item, index) => (
                            <li key={index}>
                              <h3>{item.title}</h3>
                              <p>{item.text}</p>
                            </li>
                          ))
                        : 'Loading...'}
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
